const baseUrl = 'https://api.danblaax.net';
const secureCookie = true;
const apiVersion = 'v1';
const sentryDSN = 'https://dc53e1b1d8254024a779c9e9c110aa16@o95413.ingest.sentry.io/1777978';
const env = 'staging';
export {
	env,
	baseUrl,
	secureCookie,
	apiVersion,
	sentryDSN,
};